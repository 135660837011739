<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card elevation="0">
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO Será
          Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                to="/base/customers/create"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Cliente</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                to="/base/customers/create"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="owners"
          :search="search"
          no-data-text="Sem dados no momento"
          no-results-text="Sem dados para sua busca"
          :header-props="{ sortByText: 'Ordenar por' }"
          loading-text="Carregando dados"
          class="elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :to="`/base/customers/${item.id}/edit`">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
            <v-icon
              v-if="verifyDeletePermission()"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog
        v-model="openDialog"
        width="1000"
        :fullscreen="fullScreen"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card elevation="0">
          <v-toolbar color="primary" dark flat style="padding-right: 10px !important">
            <v-toolbar-title class="overline">Clientes</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon @click="fullScreen ? (fullScreen = false) : (fullScreen = true)">{{
                setIconScreen()
              }}</v-icon>
              <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-tabs v-model="tab" centered>
                <v-tabs-slider></v-tabs-slider>
                <v-tab class="no-uppercase" href="#tab-1"> Dados Principais </v-tab>
                <v-tab class="no-uppercase" href="#tab-2">Endereço </v-tab>
                <v-tab class="no-uppercase" href="#tab-3">Complemento </v-tab>
                <v-tab class="no-uppercase" href="#tab-4">Banco</v-tab>
              </v-tabs>
              <br />
              <v-tabs-items v-model="tab">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-tab-item :value="'tab-1'">
                    <v-row>
                      <v-col cols="12" sm="3">
                        <v-autocomplete
                          outlined
                          :items="personTypes"
                          item-value="id"
                          item-text="label"
                          label="Tipo Pessoa"
                          v-model="owner.person.personType"
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          :label="
                            owner.person.personType === 'JURIDICA'
                              ? 'Razão Social'
                              : 'Nome'
                          "
                          v-model="owner.person.corporateName"
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          :label="
                            owner.person.personType === 'JURIDICA'
                              ? 'Nome Fantasia'
                              : 'Apelido'
                          "
                          v-model="owner.person.tradeName"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          v-mask="
                            owner.person.personType === 'JURIDICA' ? maskCnpj : maskCpf
                          "
                          :label="owner.person.personType === 'JURIDICA' ? 'CNPJ' : 'CPF'"
                          v-model="owner.person.idDocument"
                          @change="searchCompany()"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          :label="
                            owner.person.personType === 'JURIDICA'
                              ? 'Inscrição Estadual'
                              : 'RG/SSP'
                          "
                          v-model="owner.person.stateDocument"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          v-mask="maskPhone"
                          label="Fone Fixo"
                          v-model="owner.contact.phoneLandline"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          v-mask="maskPhoneMobile"
                          label="Celular"
                          v-model="owner.contact.phoneMobile"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          v-mask="maskPhoneMobile"
                          label="WhatsApp"
                          v-model="owner.contact.whatsApp"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Email"
                          v-model="owner.contact.email"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" class="mt-n8">
                        <v-text-field
                          outlined
                          v-money="money"
                          label="Renda Mensal R$"
                          v-model.lazy="owner.person.salaryOrInvoice"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="mt-n8">
                        <v-text-field
                          outlined
                          :label="
                            owner.person.personType === 'JURIDICA'
                              ? 'Atividade Principal'
                              : 'Profissão'
                          "
                          v-model="owner.person.professionOrMainActivity"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="mt-n8">
                        <v-text-field
                          outlined
                          :label="
                            owner.person.personType === 'JURIDICA'
                              ? 'Contador'
                              : 'Empresa Empregadora'
                          "
                          v-model="owner.person.workCompany"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        :disabled="!valid"
                        :loading="loadingAction"
                        width="200px"
                        color="#2ca01c"
                        dark
                        @click="validate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item :value="'tab-2'">
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          outlined
                          v-mask="'#####-###'"
                          label="CEP"
                          v-model="owner.address.zipCode"
                          required
                          @change="searchAddress()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          label="Logradouro"
                          v-model="owner.address.street"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          outlined
                          label="Número"
                          v-model="owner.address.number"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Complemento"
                          v-model="owner.address.complement"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Bairro"
                          v-model="owner.address.zone"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Cidade"
                          v-model="owner.address.city"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" class="mt-n8">
                        <v-text-field
                          outlined
                          label="UF"
                          v-model="owner.address.state"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        class="mt-n8"
                        v-if="owner.person.personType === 'FISICA'"
                      >
                        <v-textarea
                          outlined
                          label="Observações"
                          :rows="5"
                          v-model="owner.notes"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        dark
                        :disabled="!valid"
                        :loading="loadingAction"
                        width="200px"
                        color="#2ca01c"
                        @click="validate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item :value="'tab-3'">
                    <v-row>
                      <v-col cols="12" sm="3" v-if="owner.person.personType === 'FISICA'">
                        <v-select
                          outlined
                          v-model="owner.person.gender"
                          :items="genders"
                          item-text="text"
                          item-value="value"
                          label="Sexo"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="3" v-if="owner.person.personType === 'FISICA'">
                        <v-select
                          outlined
                          v-model="owner.person.maritalStatus"
                          :items="['Casado(a)', 'Solteiro(a)']"
                          label="Estado Cívil"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" v-if="owner.person.personType === 'FISICA'">
                        <v-text-field
                          outlined
                          label="Conjuge"
                          v-model="owner.person.conjuge"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        v-if="owner.person.personType === 'FISICA'"
                        class="mt-n8"
                      >
                        <v-text-field
                          outlined
                          label="Nome do Pai"
                          v-model="owner.person.fatherName"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        v-if="owner.person.personType === 'FISICA'"
                        class="mt-n8"
                      >
                        <v-text-field
                          outlined
                          label="Nome da Mãe"
                          v-model="owner.person.motherName"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        :class="owner.person.personType === 'FISICA' ? 'mt-n8' : ''"
                      >
                        <v-text-field
                          outlined
                          type="date"
                          :label="
                            owner.person.personType === 'JURIDICA'
                              ? 'Data Fundação'
                              : 'Data Nascimento'
                          "
                          v-model="owner.person.birthDate"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        class="mt-n8"
                        v-if="owner.person.personType === 'JURIDICA'"
                      >
                        <v-textarea
                          outlined
                          label="Observações"
                          :rows="6"
                          v-model="owner.notes"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        :disabled="!valid"
                        :loading="loadingAction"
                        width="200px"
                        color="#2ca01c"
                        dark
                        @click="validate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item :value="'tab-4'" color="#0d0d0d">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          outlined
                          :items="banks"
                          item-text="name"
                          label="Banco"
                          v-model="bankSelected"
                          return-object
                          no-data-text="Erro ao Carregar Lista de Bancos... Verifique sua conexão"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          disabled
                          outlined
                          label="Banco Nª"
                          v-model="bankSelected.code"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-select
                          outlined
                          :items="['CORRENTE', 'POUPANÇA']"
                          label="Tipo Conta"
                          v-model="owner.bank.accountType"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Agencia"
                          v-model="owner.bank.agency"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Conta"
                          v-model="owner.bank.account"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" class="mt-n8">
                        <v-text-field
                          outlined
                          label="OP"
                          v-model="owner.bank.operation"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-autocomplete
                          outlined
                          :items="['FISICA', 'JURIDICA']"
                          label="Tipo Titular"
                          v-model="owner.bank.accountPersonType"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          outlined
                          :label="
                            owner.bank.accountPersonType === 'JURIDICA' ? 'CNPJ' : 'CPF'
                          "
                          v-mask="
                            owner.bank.accountPersonType === 'JURIDICA'
                              ? maskCnpj
                              : maskCpf
                          "
                          v-model.lazy="owner.bank.document"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="5" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Titular"
                          v-model="owner.bank.holder"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Chave Pix"
                          v-model="owner.bank.pixKey"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        :disabled="!valid"
                        :loading="loadingAction"
                        width="200px"
                        color="#2ca01c"
                        dark
                        @click="validate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                </v-form>
              </v-tabs-items>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import axios from "axios";
import { VMoney } from "v-money";
import SearchAddressService from "@/services/address.service";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";

export default Vue.extend({
  name: "Company",
  directives: { money: VMoney },
  components: { InternalMenu, PageHeader },
  data: () => ({
    title: "Clientes",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Aniversariantes",
            to: "/customers/birthdays",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Funcionários",
            to: "/base/employees",
            permission: "gerentor.person.employees.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Fornecedores",
            to: "/base/suppliers",
            permission: "gerentor.suppliers.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Clientes",
        disabled: true,
        href: "/base/customers",
      },
    ],
    headerProps: {
      sortByText: "Ordenar por",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    tab: null,
    deleteDialog: false,
    owners: [],
    zipCode: "",
    transferTypes: [
      {
        id: "PIX",
        label: "Pix",
      },
      {
        id: "DEPOSITO_BANCARIO",
        label: "Depósito Bancário",
      },
      {
        id: "DINHEIRO",
        label: "Dinheiro",
      },
      {
        id: "CHEQUE_PRE_DATADO",
        label: "Cheque Pré-datado",
      },
      {
        id: "CHEQUE_AVISTA",
        label: "Cheque Avista",
      },
    ],
    personTypes: [
      {
        id: "FISICA",
        label: "Física",
      },
      {
        id: "JURIDICA",
        label: "Juridica",
      },
    ],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    maskPhone: "(##)####-####",
    maskCpf: "###.###.###-##",
    maskCnpj: "##.###.###/####-##",
    maskPhoneMobile: "(##)#####-####",
    banks: [],
    valid: true,
    bankSelected: {
      name: "",
      code: "",
      fullName: "",
    },
    owner: {
      active: true,
      notes: "",
      person: {
        corporateName: "",
        tradeName: "",
        idDocument: "",
        personType: "",
        stateDocument: "",
        gender: "",
        maritalStatus: "",
        professionOrMainActivity: "",
        salaryOrInvoice: "",
        workCompany: "",
        fatherName: "",
        motherName: "",
        conjugeDocument: "",
      },
      address: {
        street: "",
        number: "",
        complement: "",
        zone: "",
        city: "",
        state: "",
        zipCode: "",
      },
      contact: {
        email: "",
        phoneLandline: "",
        phoneMobile: "",
        whatsApp: "",
      },
      bank: {
        bankName: "",
        bankCode: "",
        agency: "",
        account: "",
        operation: "",
        holder: "",
        document: "",
        accountyoe: "",
        accountPersonType: "",
      },
    },

    id: "",
    openDialog: false,
    fullScreen: false,
    genders: [
      {
        value: "F",
        text: "Feminino",
      },
      {
        value: "M",
        text: "Masculino",
      },
      {
        value: "N",
        text: "Não Informado",
      },
    ],
    headers: [
      {
        text: "Nome/Razão Social",
        align: "start",
        value: "person.corporateName",
        filtering: true,
      },
      {
        text: "Celular",
        align: "start",
        value: "contact.phoneMobile",
        filtering: true,
      },
      {
        text: "CPF/CNPJ",
        align: "start",
        value: "person.idDocument",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },

    async searchAddress() {
      const zipCode = this.owner.address.zipCode.replace(/([^\d])+/gim, "");
      const res = await SearchAddressService(zipCode);
      if (res.success) {
        res.address.zipCode = this.owner.address.zipCode;
        this.owner.address = res.address;
      } else {
        this.snackbar.text = "Houve erro ao buscar o CEP";
        this.snackbar.color = "orange";
        this.snackbar.opened = true;
      }
    },

    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    searchBanks() {
      axios.get(`https://brasilapi.com.br/api/banks/v1`).then((res) => {
        const { data } = res;
        this.banks = data;
      });
    },
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    verifyDeletePermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.admin) return true;
      if (
        user &&
        user?.group?.permissions &&
        user?.group?.permissions.includes("digitech_plus.immobile.owners.delete_one")
      ) {
        return true;
      }
      return false;
    },
    deleteItem() {
      http.delete(`gerentor/customers/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.closeDialog();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getItems() {
      this.loading = true;
      http.get("gerentor/customers").then((data) => {
        this.owners = data.data;
        this.loading = false;
      });
    },
    registerOrUpdate() {
      this.loadingAction = true;
      let monetary = this.owner.person.salaryOrInvoice.toString().replace("R$ ", "");
      monetary = monetary.replace(".", "");
      monetary = monetary.replace(",", ".") * 1;
      this.owner.person.salaryOrInvoice = monetary;
      this.owner.bank.bankName = this.bankSelected.name;
      this.owner.bank.bankCode = this.bankSelected.code;
      if (this.id) {
        http.put(`gerentor/customers/${this.id}`, this.owner).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("gerentor/customers", this.owner).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      location.reload();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    editItem(item) {
      this.id = item.id;
      this.owner = item;
      this.bankSelected.name = this.owner.bank.bankName;
      this.bankSelected.code = this.owner.bank.bankCode;
      this.openDialog = true;
    },
    searchCompany() {
      if (this.owner.person.personType === "JURIDICA") {
        const cnpj = this.owner.person.idDocument.replace(/([^\d])+/gim, "");
        axios.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`).then((res) => {
          const { data } = res;
          this.owner.person.corporateName = data.razao_social;
          this.owner.person.tradeName = data.nome_fantasia;
          this.owner.person.professionOrMainActivity =
            data.cnae_fiscal + "-" + data.cnae_fiscal_descricao;
          this.owner.address.street = data.logradouro;
          this.owner.address.zone = data.bairro;
          this.owner.address.city = data.municipio;
          this.owner.address.state = data.uf;
          this.owner.address.number = data.numero;
          this.owner.address.complement = data.complemento;
          this.owner.address.zipCode = data.cep;
        });
      }
    },
  },
  mounted() {
    this.getItems();
    this.searchBanks();
  },
});
</script>

<style>
.label-outlined {
  font-size: 12px;
  color: grey;
}
</style>
